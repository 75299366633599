<template>
  <b-container class="settings-tab bg-light page-wrapper">
    <b-row class="main-content-wrapper pb-0" no-gutters>
      <b-col>
        <settings-submenu></settings-submenu>
      </b-col>
    </b-row>
    <b-row class="main-content-wrapper pt-0" no-gutters>
      <b-col sm="12" md="5" lg="3" class="detail-text text-left py-4 pr-5">
        <h3 class="text-dark">{{ $t(translationPath + 'title') }}</h3>
        <p class="text-gray">{{ $t(translationPath + 'description') }}</p>
      </b-col>
      <b-col class="py-2 px-0">
        <b-tabs v-model="activeTab" card class="transparent-tabs">
          <b-tab :title="$t('settings.submenu.your_details')" class="pt-3">
            <account-settings/>
          </b-tab>
          <b-tab :title="$t('settings.submenu.change_password')" class="pt-3">
            <change-password-settings/>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SettingsSubmenu from '../SettingsSubmenu'
import TitleMixins from '@/mixins/title'
import AccountSettings from './YourDetailsAccountSettings'
import ChangePasswordSettings from './YourDetailsChangePassword'
export default {
  name: 'YourDetails',
  components: { SettingsSubmenu, AccountSettings, ChangePasswordSettings },
  mixins: [TitleMixins],
  data () {
    return {
      translationPath: 'settings.tab_your_details.',
      activeTab: 0
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    titleMeta () {
      return this.$t('settings.submenu.your_details')
    }
  },
  methods: {
    ...mapActions('user', ['fetchData'])
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/settings.scss';
</style>
