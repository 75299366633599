<template>
  <b-form @submit.prevent="onSaveUserDetails">
    <!-- Password -->
    <b-form-group
      label-class="align-self-center pr-md-0 pr-lg-4 text-black"
      label-for="password"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      required
      class="pt-md-4"
      :invalid-feedback="invalidPasswordFeedback"
    >
      <template slot="label">{{
        $t(translationPath + "password_label")
      }}</template>
      <b-form-input
        type="password"
        id="password"
        class="rounded-pill"
        :state="$v.password.$dirty ? !$v.password.$error : null"
        v-model="password"
        @input="$v.password.$touch()"
      ></b-form-input>
    </b-form-group>

    <!-- Password confirmation -->
    <b-form-group
      label-class="align-self-center pr-md-0 pr-lg-4 text-black"
      label-for="password_confirm"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      required
      class="pt-md-4"
      :invalid-feedback="invalidPasswordConfirmFeedback"
    >
      <template slot="label">{{
        $t(translationPath + "password_confirm_label")
      }}</template>
      <b-form-input
        type="password"
        id="password_confirm"
        class="rounded-pill"
        :state="$v.passwordConfirm.$dirty ? !$v.passwordConfirm.$error : null"
        v-model="passwordConfirm"
        @input="$v.passwordConfirm.$touch()"
      ></b-form-input>
    </b-form-group>
    <b-row>
      <b-col class="pt-4 pt-md-1">
        <b-button type="submit" variant="primary" class="float-right btn-save btn-sm">
          {{ $t(translationPath + "change_password") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import { required, minLength } from 'vuelidate/lib/validators'

const isPasswordConfirmed = (getter) =>
  function () {
    return this.isPasswordConfirmed
  }

export default {
  name: 'ChangePasswordSettings',
  data () {
    return {
      password: null,
      passwordConfirm: null,
      isPasswordConfirmed: true,
      translationPath: 'settings.change_password.'
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    passwordConfirm: {
      required,
      passwordConfirmed: isPasswordConfirmed()
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    invalidPasswordFeedback () {
      if (this.$v.password.required === false) {
        return this.$i18n.t(this.translationPath + 'errors.password_required')
      }
      if (this.$v.password.minLength === false) {
        return this.$i18n.t(this.translationPath + 'errors.password_minlength')
      }
      return ''
    },
    invalidPasswordConfirmFeedback () {
      if (this.$v.password.required === false) {
        return this.$i18n.t(
          this.translationPath + 'errors.password_confirmation_required'
        )
      }
      if (this.$v.passwordConfirm.passwordConfirmed === false) {
        return this.$i18n.t(
          this.translationPath + 'errors.password_confirmation_fail'
        )
      }
      return ''
    },
    validated () {
      return !this.$v.$invalid
    }
  },
  methods: {
    ...mapActions('user', ['fetchData']),
    async onSaveUserDetails () {
      this.$v.$touch()
      if (this.validated) {
        const userData = {
          password: this.password,
          password_confirmation: this.passwordConfirm,
          firstname: this.currentUser.firstname,
          lastname: this.currentUser.lastname,
          email: this.currentUser.email,
          default_lang: this.currentUser.default_lang
        }
        await axios
          .put(
            `${process.env.VUE_APP_ROOT_API}/users/` + this.currentUser.id,
            userData
          )
          .then((response) => {
            this.password = null
            this.passwordConfirm = null
            this.$v.$reset()
            this.$bvToast.toast(
              this.$t(this.translationPath + 'details_updated'),
              {
                title: this.$t(this.translationPath + 'title'),
                variant: 'success',
                solid: true,
                'auto-hide-delay': 3000
              }
            )
          })
          .catch(function (error) {
            console.error(error)
          })
      }
    },
    checkIsPasswordConfirmed () {
      if (this.password === this.passwordConfirm) {
        this.isPasswordConfirmed = true
      } else {
        this.isPasswordConfirmed = false
      }
    }
  },
  watch: {
    password () {
      this.checkIsPasswordConfirmed()
    },
    passwordConfirm () {
      this.checkIsPasswordConfirmed()
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/settings.scss";
</style>
