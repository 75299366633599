<template>
  <div class="component-settings-your-details-account-settings px-4">
    <b-form @submit.prevent="onSaveUserDetails">

    <h3 class="pt-4">{{$t(translationPath + 'common_details')}}</h3>
    <div v-html="$t(translationPath + 'common_details_description')"></div>

    <template v-if="!isLoaded">
      <loader />
    </template>
    <template v-else>
      <!-- Firstname -->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="firstname"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        required
        class="pt-md-2 pt-lg-4"
        :invalid-feedback="invalidFirstnameFeedback"
      >
        <template slot="label">{{
            $t(translationPath + "firstname_label")
          }}
        </template>
        <b-form-input
          id="firstname"
          class="rounded-pill"
          :placeholder="$t(translationPath + 'firstname_placeholder')"
          :state="$v.firstname.$dirty ? !$v.firstname.$error : null"
          v-model="firstname"
          @input="$v.firstname.$touch()"
        >
        </b-form-input>
      </b-form-group>

      <!-- Lastname -->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="lastname"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        required
        class="pt-md-2 pt-lg-4"
        :invalid-feedback="invalidLastnameFeedback"
      >
        <template slot="label">{{
            $t(translationPath + "lastname_label")
          }}
        </template>
        <b-form-input
          id="lastname"
          class="rounded-pill"
          :placeholder="$t(translationPath + 'lastname_placeholder')"
          :state="$v.lastname.$dirty ? !$v.lastname.$error : null"
          v-model="lastname"
          @input="$v.lastname.$touch()"
        >
        </b-form-input>
      </b-form-group>

      <!-- Email -->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="email"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        required
        class="pt-md-2 pt-lg-4"
        :invalid-feedback="invalidEmailFeedback"
      >
        <template slot="label">{{
            $t(translationPath + "email_label")
          }}
        </template>
        <b-form-input
          id="email"
          class="rounded-pill"
          :placeholder="$t(translationPath + 'email_placeholder')"
          :state="$v.email.$dirty ? !$v.email.$error : null"
          v-model="email"
          @input="$v.email.$touch()"
        ></b-form-input>
      </b-form-group>

      <!-- Mobile -->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="mobile"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-2 pt-lg-4"
        :invalid-feedback="invalidMobileFeedback"
      >
        <template slot="label">{{
            $t(translationPath + "mobile_label")
          }}
        </template>
        <b-form-input
          id="mobile"
          class="rounded-pill"
          :placeholder="$t(translationPath + 'mobile_placeholder')"
          :state="$v.mobile.$dirty ? !$v.mobile.$error : null"
          v-model="mobile"
          @input="$v.mobile.$touch()"
        >
        </b-form-input>
      </b-form-group>

      <!-- Language -->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="language"
        label-cols-xl="3"
        label-cols-lg="3"
        label-align-lg="right"
        required
        class="pt-md-2 pt-lg-4"
      >
        <template slot="label">{{
            $t(translationPath + "language_label")
          }}
        </template>
        <b-form-select
          id="language"
          class="rounded-pill"
          v-model="language"
          :options="languageOptions"
        ></b-form-select>
      </b-form-group>

      <!-- Country -->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="country"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-2 pt-lg-4"
        :invalid-feedback="invalidCountryFeedback"
      >
        <template slot="label">{{$t(translationPath + "country_label")}}</template>
        <b-form-select
          id="country"
          class="rounded-pill"
          v-model="country"
          :options="countryOptions"
          :state="$v.country.$dirty ? !$v.country.$error : null"
          @input="$v.country.$touch()"
        ></b-form-select>
      </b-form-group>

      <!--DOB-->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="dob"
        label-cols-xl="3"
        label-cols-lg="3"
        label-align-lg="right"
        class="pt-md-2 pt-lg-4"
        :state="$v.birth_year.$dirty ? !$v.birth_year.$error : null"
        :invalid-feedback="invalidBirthYearFeedback"
        :description="$t(translationPath + 'birth_year_description')"
      >
        <template slot="label">{{ $t(translationPath + "birth_year") }}</template>
        <b-form-input
          id="dob"
          class="rounded-pill"
          type="number"
          min="1900"
          max="2020"
          :state="$v.birth_year.$dirty ? !$v.birth_year.$error : null"
          v-model="birth_year"
          @input="$v.birth_year.$touch()"
          :placeholder="$t(translationPath + 'birth_year_placeholder')"
        ></b-form-input>
      </b-form-group>

      <h3 class="pt-4">{{$t(translationPath + 'bank_id_settings')}}</h3>
      <div v-html="$t(translationPath + 'bank_id_settings_description')"></div>

      <!--Personal number-->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="personal_number"
        label-cols-xl="3"
        label-cols-lg="3"
        label-align-lg="right"
        class="pt-md-2 pt-lg-4"
        :invalid-feedback="invalidPersonalNumberFeedback"
        :description="$t(translationPath + 'personal_number_description')"
      >
        <template slot="label">{{ $t(translationPath + "personal_number") }}</template>
        <b-form-input
          id="personal_number"
          class="rounded-pill"
          type="text"
          :state="$v.personal_number.$dirty ? !$v.personal_number.$error : null"
          v-model="personal_number"
          @input="$v.personal_number.$touch()"
          :placeholder="$t(translationPath + 'personal_number_placeholder')"
        ></b-form-input>
      </b-form-group>

      <!-- Disable user_login -->
      <b-form-group
        label-class="align-self-center pr-md-0 pr-lg-4 text-black"
        label-for="mobile"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-2 pt-lg-4"
        :description="$t(translationPath + 'disabled_user_login_description')"
        :invalid-feedback="invalidDisabledUsernameLoginFeedback"
        v-if="this.personal_number!== null && this.personal_number !== ''"
      >
        <template slot="label">{{ $t( translationPath + 'disabled_user_login') }}
        </template>
        <b-form-select
          id="disable"
          class="rounded-pill"
          v-model="disable_username_login"
          :options="disableUsernameOptions"
          :state="$v.disable_username_login.$dirty ? !$v.disable_username_login.$error : null"
          @input="$v.disable_username_login.$touch()"
          @change="resetPersonal"
        ></b-form-select>
      </b-form-group>

      <b-row>
        <b-col class="pt-4 pt-md-1">
          <b-button type="submit" variant="primary" class="float-right btn-save btn-sm">
            {{ $t(translationPath + "save") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { required, minLength, between } from 'vuelidate/lib/validators'
import { SET_SETTINGS_ACTIVE_LANG_ID } from '@/store/actions/settings'

const validEmail = function (val) {
  // eslint-disable-next-line
  return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g).test(val)
}
const validPersonalNumber = function () {
  // Ok to not be given
  if (this.personal_number === null || this.personal_number === '') {
    return true
  }

  // But if given, fulfill theses requirements
  if (this.personal_number !== undefined && this.personal_number.length < 10) {
    return false
  }
  return (/^(?:(19)?\d\d|(20)?[0-3]\d)(?:0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])-?\d{4}$/g).test(this.personal_number)
}
const isEmailOkAndNotUsedInDb = function () {
  return this.isEmailOkAndNotUsedInDb
}
const checkDisableUsernameLoginRule = function (val) {
  if (val === 1) {
    if (this.personal_number !== null) {
      return true
    }
    return false
  } else {
    return true
  }
}

export default {
  name: 'AccountSettings',
  components: { Loader },
  data () {
    return {
      firstname: null,
      lastname: null,
      email: null,
      birth_year: null,
      personal_number: null,
      language: 'sv',
      disable_username_login: 0,
      mobile: null,
      country: 'sweden',
      isEmailOkAndNotUsedInDb: true,
      translationPath: 'settings.tab_your_details.',
      isLoaded: false
    }
  },
  validations: {
    firstname: {
      required, minLength: minLength(2)
    },
    lastname: {
      required, minLength: minLength(2)
    },
    birth_year: {
      between: between(1922, 2022)
    },
    personal_number: {
      is_personal_number_valid: validPersonalNumber
    },
    email: {
      required, validEmail, isEmailOk: isEmailOkAndNotUsedInDb
    },
    mobile: {
      minLength: minLength(6)
    },
    country: {
      minLength: minLength(2)
    },
    disable_username_login: {
      is_personal_number_set: checkDisableUsernameLoginRule
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    disableUsernameOptions () {
      return [
        { text: this.$t('common.no'), value: 0 },
        { text: this.$t('common.yes'), value: 1 }
      ]
    },
    languageOptions () {
      return [
        { key: 'en', value: 'en', text: this.$t('common.locals.english') },
        { key: 'sv', value: 'sv', text: this.$t('common.locals.swedish') },
        { key: 'da', value: 'da', text: this.$t('common.locals.danish') }
      ]
    },
    countryOptions () {
      return [
        { value: 'sweden', text: this.$t('common.country.sweden') },
        { value: 'denmark', text: this.$t('common.country.denmark') },
        { value: 'norway', text: this.$t('common.country.norway') },
        { value: 'finland', text: this.$t('common.country.finland') },
        { value: null, text: this.$t('common.country.other') }
      ]
    },
    invalidFirstnameFeedback () {
      if (this.$v.firstname.required === false) { return this.$i18n.t(this.translationPath + 'errors.firstname_required') }
      if (this.$v.firstname.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.firstname_minlength') }
      return ''
    },
    invalidLastnameFeedback () {
      if (this.$v.lastname.required === false) { return this.$i18n.t(this.translationPath + 'errors.lastname_required') }
      if (this.$v.lastname.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.lastname_minlength') }
      return ''
    },
    invalidBirthYearFeedback () {
      if (this.$v.birth_year.between === false) { return this.$i18n.t(this.translationPath + 'errors.invalid_year') }
      return ''
    },
    invalidPersonalNumberFeedback () {
      if (this.$v.personal_number.is_personal_number_valid === false) { return this.$i18n.t(this.translationPath + 'errors.invalid_personal_number_format') }
      return ''
    },
    invalidEmailFeedback () {
      if (this.$v.email.required === false) { return this.$i18n.t(this.translationPath + 'errors.email_required') }
      if (this.$v.email.validEmail === false) { return this.$i18n.t(this.translationPath + 'errors.email_invalid') }
      if (this.$v.email.isEmailOk === false) { return this.$i18n.t(this.translationPath + 'errors.email_exist') }
      return ''
    },
    invalidMobileFeedback () {
      if (this.$v.mobile.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.mobile_minlength') }
      return ''
    },
    invalidCountryFeedback () {
      if (this.$v.country.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.country_minlength') }
      return ''
    },
    invalidDisabledUsernameLoginFeedback () {
      if (this.$v.disable_username_login.is_personal_number_set === false) { return this.$i18n.t(this.translationPath + 'errors.personal_number_is_not_ok') }
      return ''
    },
    validated () {
      return !this.$v.$invalid
    }
  },
  methods: {
    ...mapActions('user', ['fetchData']),
    resetPersonal () {
      const _self = this
      _self.personal_number = null
      _self.$v.personal_number.$reset()
    },
    async emailExists () {
      const email = this.email
      const status = await axios.post(`${process.env.VUE_APP_ROOT_API}/users/email/exists`, { email })
        .then((response) => {
          let status = response.data.data.user_exists
          if (status && this.currentUser.email === this.email) {
            status = false
          }
          return status
        })

      this.isEmailOkAndNotUsedInDb = !status
    },
    async onSaveUserDetails () {
      this.$v.$touch()
      if (this.validated) {
        await this.emailExists()
        if (!this.isEmailOkAndNotUsedInDb) {
          return false
        }
        const userData = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          default_lang: this.language,
          birth_year: this.birth_year ? Number(this.birth_year) : null,
          personal_number: this.personal_number,
          mobile: this.mobile ? this.mobile : null,
          country: this.country ? this.country : null,
          disable_username_login: this.disable_username_login
        }
        await axios.put(`${process.env.VUE_APP_ROOT_API}/users/` + this.currentUser.id, userData)
          .then((response) => {
            this.$store.dispatch(SET_SETTINGS_ACTIVE_LANG_ID, this.language)
            this.fetchData()
            this.$v.$reset()
            this.$emit('save')
            this.$bvToast.toast(this.$t(this.translationPath + 'details_updated').toString(), {
              title: this.$t(this.translationPath + 'title').toString(), variant: 'success', solid: true, 'auto-hide-delay': 3000
            })
          })
          .catch(function (error) {
            console.error(error)
          })
      }
    },
    loadData () {
      this.isLoaded = false
      axios.get(`${process.env.VUE_APP_ROOT_API}/me?with_all_info=true`)
        .then(response => {
          this.firstname = response.data.data.firstname
          this.lastname = response.data.data.lastname
          this.email = response.data.data.email
          this.language = response.data.data.default_lang
          this.birth_year = response.data.data.birth_year
          this.personal_number = response.data.data.personal_number
          this.mobile = response.data.data.mobile
          this.country = response.data.data.country ? response.data.data.country : null
          this.disable_username_login = response.data.data.disable_username_login
        })
        .catch(err => {
          console.error(err)
        })
        .then(() => {
          this.isLoaded = true
        })
    }
  },
  watch: {
    email () {
      this.isEmailOkAndNotUsedInDb = true
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/settings.scss';
</style>
